import React from "react";
import "./Title.css";
import CoreCogniDashboard from "../graphics/images/CoreCogni_Dashboard.png";
import { Button } from "@chakra-ui/react";

export default function Title() {
  return (
    <div className="title-container">
      <div className="title">
        <h1 className="title-heading">
          Your Assistant to Understand Employee Emotions
        </h1>
        <p className="sub-title">
          CoreCogni offers a comprehensive solution to understand and analyze
          employee emotions in real-time, provide a deep understanding of
          workplace sentiment. By harnessing this data, organizations can make
          data-driven decisions and cultivate a positive work environment,
          leading to enhanced productivity and overall success.
        </p>
        <div className="early-access-button-container">
          <a href="https://tally.so/r/m6L7DA" target="_blank" rel="noreferrer">
            <Button colorScheme="brand" width="200px">
              Get Early Access
            </Button>
          </a>
        </div>
        <div className="image-container">
          <img src={CoreCogniDashboard} alt="CoreCogni" />
        </div>
        <p className="title-description">
          Enhance your organizational culture to new heights with our powerful
          analytics platform that provides an in-depth look into your team
          dynamics and cultural performance indicators.
        </p>
      </div>
    </div>
  );
}
