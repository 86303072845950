import React from "react";
import { Envelope, Linkedin } from "react-bootstrap-icons";
import { Link } from "@chakra-ui/react";

export default function SummaryContact() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: "50px 20px 10px 10px",
      }}
    >
      <div style={{ display: "flex" }}>
        <div style={{ marginLeft: "10px" }}>
          <a
            href="https://www.linkedin.com/company/corecogni/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Linkedin size={24} />
          </a>
        </div>
        <div style={{ marginLeft: "10px" }}>
          <a href="mailto:contact@corecogni.com">
            <Envelope size={26} />
          </a>
        </div>
        <div
          style={{
            marginLeft: "10px",
            color: "#0072BB",
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          <Link href="mailto:contact@corecogni.com">contact@corecogni.com</Link>
        </div>
      </div>
    </div>
  );
}
