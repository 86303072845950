import React from "react";
import { Divider } from "@chakra-ui/react";
import "./Summary.css";
import SummaryLogo from "./SummaryLogo";
import SummaryContact from "./SummaryContact";

export default function Summary() {
  return (
    <>
      <Divider style={{ borderColor: "#AAAAAA" }} />
      <div className="summary-container">
        <SummaryLogo />
        <SummaryContact />
      </div>
    </>
  );
}
