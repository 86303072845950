import React from "react";
import {
  ArrowRepeat,
  Clipboard2CheckFill,
  Diagram3Fill,
  ExclamationTriangle,
  Fan,
  Gear,
  GraphUpArrow,
  Pen,
} from "react-bootstrap-icons";
import "./FeaturesDescription.css";

export default function FeaturesDescription() {
  return (
    <div className="features-outer-container">
      <div className="features-description-container">
        <div className="features-description-row">
          <div className="feature-description-left">
            <div className="feature-description">
              <div>
                <Gear color="#0072BB" size={25} style={{ marginLeft: "5px" }} />
              </div>
              <div className="feature-title">Custom Properties</div>
              <div className="feature-description-content">
                Empower tailored research with our customizable properties.
                Measure 'Work-life Balance', 'Growth', 'Transparency',
                'Burnout', 'Stress', or any custom property you need, enabling
                precise analytics for your organization.
              </div>
            </div>
          </div>
          <div className="feature-description-right">
            <div className="feature-description">
              <div>
                <Clipboard2CheckFill
                  color="#0072BB"
                  size={25}
                  style={{ marginLeft: "5px" }}
                />
              </div>
              <div className="feature-title">Templates</div>
              <div className="feature-description-content">
                Simplify Research and Surveys: Create, Customize, and Store
                Templates, or Use Predefined Ones, with Ease.
              </div>
            </div>
          </div>
        </div>

        <div className="features-description-row">
          <div className="feature-description-left">
            <div className="feature-description">
              <div>
                <Fan color="#0072BB" size={25} style={{ marginLeft: "5px" }} />
              </div>
              <div className="feature-title">Automate</div>
              <div className="feature-description-content">
                Effortlessly select parameters to generate automated,
                data-driven questions for deeper insights.
              </div>
            </div>
          </div>
          <div className="feature-description-right">
            <div className="feature-description">
              <div>
                <Pen color="#0072BB" size={25} style={{ marginLeft: "5px" }} />
              </div>
              <div className="feature-title">Self Anonymous Feedbacks</div>
              <div className="feature-description-content">
                Encourages honest feedback and empowers employees to share
                anonymously and amplify their voices.
              </div>
            </div>
          </div>
        </div>

        <div className="features-description-row">
          <div className="feature-description-left">
            <div className="feature-description">
              <div>
                <GraphUpArrow
                  color="#0072BB"
                  size={25}
                  style={{ marginLeft: "5px" }}
                />
              </div>
              <div className="feature-title">Instant Results</div>
              <div className="feature-description-content">
                Results are published instantly with the detailed dashboard
                view, eliminating the need for external files like spreadsheets
                and PDFs.
              </div>
            </div>
          </div>
          <div className="feature-description-right">
            <div className="feature-description">
              <div>
                <Diagram3Fill
                  color="#0072BB"
                  size={25}
                  style={{ marginLeft: "5px" }}
                />
                <div className="feature-title">Multi-level Analytics</div>
                <div className="feature-description-content">
                  Get team-wise as well as entire organization analytics that
                  facilitates improved interpretation of team level dynamics.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="features-description-row">
          <div className="feature-description-left">
            <div className="feature-description">
              <div>
                <ArrowRepeat
                  color="#0072BB"
                  size={25}
                  style={{ marginLeft: "5px" }}
                />
              </div>
              <div className="feature-title">Continuous Frequent Analytics</div>
              <div className="feature-description-content">
                Easy-to-use analytics platform enables regular analysis, leading
                to speedy enhancements in overall workplace dynamics.
              </div>
            </div>
          </div>
          <div className="feature-description-right">
            <div className="feature-description">
              <div>
                <ExclamationTriangle
                  color="#0072BB"
                  size={25}
                  style={{ marginLeft: "5px" }}
                />
              </div>
              <div className="feature-title">Early Warning System</div>
              <div className="feature-description-content">
                Recieve alert about any deviations from the norm in your work
                culture, empowering organizations to address issues before they
                escalate.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
