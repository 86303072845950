import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import Wrapper from "./components/Wrapper";
import { theme } from "./theme/theme";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PageNotFound from "./components/PageNotFound";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Routes>
          <Route exact path="/" element={<Wrapper />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
