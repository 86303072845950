import React from "react";
import Features from "./Features";
import NavMenu from "./NavMenu";
import "./Wrapper.css";
import Summary from "./Summary";

export default function Wrapper() {
  return (
    <div style={{ backgroundColor: "#FAFAFA" }}>
      <div>
        <NavMenu />
      </div>
      <div className="features-outer-container">
        <div className="features-container">
          <Features />
        </div>
      </div>
      <div className="summary-outer-container">
        <Summary />
      </div>
    </div>
  );
}
