import React from "react";
import "./Pricing.css";
import { Button } from "@chakra-ui/react";

export default function Pricing() {
  return (
    <div>
      <div className="pricing-title">Pricing</div>
      <div className="pricing-categories-container">
        <div className="pricing-category">
          <div className="pricing-category-title">Standard</div>
          <div className="pricing-category-details">
            Suitable for teams with 2-100 members
          </div>
          <div className="price-amount">$5</div>
          <div className="price-details">per member/month</div>
        </div>
        <div className="pricing-category">
          <div className="pricing-category-title">Plus</div>
          <div className="pricing-category-details">
            Suitable for teams with 101-500 members
          </div>
          <div className="price-amount">$4</div>
          <div className="price-details">per member/month</div>
        </div>
        <div className="pricing-category">
          <div className="pricing-category-title">Enterprise</div>
          <div className="pricing-category-details">For 500+ team members</div>
          <div className="price-amount">Custom</div>
          <div style={{ marginTop: "30px" }}>
            <a
              href="https://tally.so/r/m6L7DA"
              target="_blank"
              rel="noreferrer"
            >
              <Button colorScheme="brand">For more details talk to us</Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
