import React from "react";
import "./Benefits.css";
import { Divider } from "@chakra-ui/react";
import EmplyoeeEmotionsImage from "../graphics/images/Employee_Emotions.png";
import AttractRetainTalentImage from "../graphics/images/Attract_Retain_Talent.png";
import SustainabilityInclusivityImage from "../graphics/images/Sustainability_Inclusivity.png";

export default function Benefits() {
  return (
    <div className="benefits-container">
      <div
        style={{
          textAlign: "center",
          marginBottom: "75px",
          fontSize: "35px",
          color: "#505050",
        }}
      >
        Why{" "}
        <span style={{ color: "#FF8603", fontWeight: "bold" }}>CoreCogni</span>
      </div>
      <div className="benefits-left">
        <img
          src={EmplyoeeEmotionsImage}
          alt="Understand People's Emotions"
          className="benefits-diagram"
        />
        <div className="benefits-description-right">
          <div
            className="benefits-description-title"
            style={{ color: "#0072BB" }}
          >
            Understand People's Emotions
          </div>
          <div className="benefits-description-content">
            <div className="benefits-description-content-points">
              <span style={{ color: "#0072BB", fontWeight: "bold" }}>
                Enhanced Employee Well-being:
              </span>{" "}
              Understand employee emotions to prioritize well-being, reducing
              stress and boosting job satisfaction.
            </div>
            <div className="benefits-description-content-points">
              <span style={{ color: "#0072BB", fontWeight: "bold" }}>
                Improved Employee Engagement:
              </span>{" "}
              Real-time insights drive tailored initiatives for higher
              motivation and commitment.
            </div>
            <div className="benefits-description-content-points">
              <span style={{ color: "#0072BB", fontWeight: "bold" }}>
                Data-Driven Decision Making:
              </span>{" "}
              Use emotional data for informed HR policies and organizational
              improvements.
            </div>
            <div className="benefits-description-content-points">
              <span style={{ color: "#0072BB", fontWeight: "bold" }}>
                Cultivating Positive Work Culture:
              </span>{" "}
              Foster teamwork, collaboration, and belonging through emotional
              understanding.
            </div>
          </div>
        </div>
      </div>
      <Divider
        style={{
          borderColor: "#AAAAAA",
          marginTop: "100px",
          marginBottom: "100px",
        }}
      />
      <div className="benefits-right">
        <img
          src={AttractRetainTalentImage}
          alt="Attract and Retain Top Talent"
          className="benefits-diagram"
        />
        <div className="benefits-description-left">
          <div
            className="benefits-description-title"
            style={{ color: "#FF8603" }}
          >
            Attract and Retain Top Talent
          </div>
          <div className="benefits-description-content">
            <div className="benefits-description-content-points">
              <span style={{ color: "#32AA32", fontWeight: "bold" }}>
                Higher Retention Rates:
              </span>{" "}
              When employees feel their emotions are understood and valued, they
              are more likely to stay loyal to the organization, leading to
              increased retention rates and reduced turnover costs.
            </div>
            <div className="benefits-description-content-points">
              <span style={{ color: "#32AA32", fontWeight: "bold" }}>
                Increased Productivity:
              </span>{" "}
              A positive work environment and engaged workforce result in higher
              productivity levels, ultimately contributing to the overall
              success and growth of the organization.
            </div>
            <div className="benefits-description-content-points">
              <span style={{ color: "#32AA32", fontWeight: "bold" }}>
                Conflict Resolution:
              </span>{" "}
              Identifying negative emotions in real-time allows for timely
              intervention and effective conflict resolution, promoting a
              harmonious work environment.
            </div>
            <div className="benefits-description-content-points">
              <span style={{ color: "#32AA32", fontWeight: "bold" }}>
                Competitive Advantage:
              </span>{" "}
              Organizations that prioritize employee well-being and utilize
              emotional insights are more likely to attract top talent and gain
              a competitive edge in the market.
            </div>
          </div>
        </div>
      </div>
      <Divider
        style={{
          borderColor: "#AAAAAA",
          marginTop: "100px",
          marginBottom: "100px",
        }}
      />
      <div className="benefits-left">
        <img
          src={SustainabilityInclusivityImage}
          alt="Ensure Inclusivity & Sustainability"
          className="benefits-diagram"
        />
        <div className="benefits-description-right">
          <div
            className="benefits-description-title"
            style={{ color: "#0072BB" }}
          >
            Ensure Inclusivity & Sustainability
          </div>
          <div className="benefits-description-content">
            <div className="benefits-description-content-points">
              <span style={{ color: "#0072BB", fontWeight: "bold" }}>
                Employee Feedback and Inclusivity:
              </span>{" "}
              Our platform encourages open communication, providing employees a
              platform to share their feelings and opinions, fostering a culture
              of inclusivity and transparency.
            </div>
            <div className="benefits-description-content-points">
              <span style={{ color: "#0072BB", fontWeight: "bold" }}>
                ESG Alignment:
              </span>{" "}
              Our platform contributes to your organization's ESG
              {" ("}Environmental, social, and corporate governance{")"} goals
              by promoting a positive work culture and employee well-being,
              enhancing social impact and governance practices.
            </div>
            <div className="benefits-description-content-points"></div>
            <div className="benefits-description-content-points"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
