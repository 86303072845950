import React from "react";
import "./NavMenu.css";
import { ReactComponent as CoreCogni } from "../graphics/CoreCogni.svg";
import { List } from "react-bootstrap-icons";
import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";

export default function NavMenu() {
  return (
    <div className="nav-menu-container">
      <div className="nav-menu">
        <div className="nav-action">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Link to="/">
                  <CoreCogni className="corecogni-logo" />
                </Link>
              </div>

              <div className="action-label">
                <HashLink smooth to="/#platform">
                  Platform
                </HashLink>
              </div>
              <div className="action-label">
                <HashLink smooth to="/#features">
                  Features
                </HashLink>
              </div>
              <div className="action-label">
                <HashLink smooth to="/#pricing">
                  Pricing
                </HashLink>
              </div>
              <div className="action-label">
                <HashLink smooth to="/#contact">
                  Contact
                </HashLink>
              </div>
            </div>
            <div className="demo-button-nav-menu">
              <a
                href="https://tally.so/r/m6L7DA"
                target="_blank"
                rel="noreferrer"
              >
                <Button colorScheme="brand">Book A Demo</Button>
              </a>
            </div>
            <div className="menu-button">
              <Menu autoSelect={false}>
                <MenuButton as={IconButton} icon={<List />} />
                <MenuList>
                  <MenuItem as={HashLink} smooth={true} to="#platform">
                    Platform
                  </MenuItem>
                  <MenuItem as={HashLink} smooth={true} to="#features">
                    Features
                  </MenuItem>
                  <MenuItem as={HashLink} smooth={true} to="#pricing">
                    Pricing
                  </MenuItem>
                  <MenuItem as={HashLink} smooth={true} to="#contact">
                    Contact
                  </MenuItem>
                  <MenuDivider />
                  <MenuItem
                    as="a"
                    href="https://tally.so/r/m6L7DA"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#0072BB", fontWeight: "bold" }}
                  >
                    Book A Demo
                  </MenuItem>
                </MenuList>
              </Menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
