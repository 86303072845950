import React from "react";

export default function PageNotFound() {
  return (
    <div
      style={{
        display: "flex",
        justifycontent: "center",
        height: "100vh",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          flex: "1 0 auto",
        }}
      >
        <div
          style={{
            color: "gray",
            fontWeight: "bold",
            fontSize: "70px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          Error 404
        </div>
        <div
          style={{
            color: "gray",
            fontSize: "25px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          Page Not Found
        </div>
      </div>
    </div>
  );
}
