import React, { useEffect, useRef } from "react";
import Demo from "./Demo";
import FeaturesDescription from "./FeaturesDescription";
import Title from "./Title";
import { Divider } from "@chakra-ui/react";
import Benefits from "./Benefits";
import Pricing from "./Pricing";
import Working from "./Working";

export default function Features() {
  const platformRef = useRef();
  const featuresRef = useRef();
  const pricingRef = useRef();
  const contactRef = useRef();

  useEffect(() => {
    scrollComponent();
  }, []);

  function scrollComponent() {
    if (window.location.hash === "#platform") {
      platformRef.current.scrollIntoView();
      platformRef.current.focus();
    } else if (window.location.hash === "#features") {
      featuresRef.current.scrollIntoView();
      featuresRef.current.focus();
    } else if (window.location.hash === "#pricing") {
      pricingRef.current.scrollIntoView();
      pricingRef.current.focus();
    } else if (window.location.hash === "#contact") {
      contactRef.current.scrollIntoView();
      contactRef.current.focus();
    }
  }

  return (
    <div>
      <div>
        <Title />
      </div>
      <Divider
        style={{
          borderColor: "#AAAAAA",
          marginTop: "50px",
          marginBottom: "50px",
        }}
      />
      <div id="platform" ref={platformRef}>
        <Benefits />
      </div>
      <Divider
        style={{
          borderColor: "#AAAAAA",
          marginTop: "100px",
          marginBottom: "100px",
        }}
      />
      <div id="features" ref={featuresRef}>
        <div
          style={{
            textAlign: "center",
            marginBottom: "50px",
            fontSize: "25px",
            fontWeight: "bold",
            color: "#505050",
          }}
        >
          HOW WE HELP YOU
        </div>
        <FeaturesDescription />
      </div>
      <Divider
        style={{
          borderColor: "#AAAAAA",
          marginTop: "100px",
          marginBottom: "100px",
        }}
      />
      <div>
        <Working />
      </div>
      <Divider
        style={{
          borderColor: "#AAAAAA",
          marginTop: "100px",
          marginBottom: "50px",
        }}
      />
      <div id="pricing" ref={pricingRef}>
        <Pricing />
      </div>
      <Divider
        style={{
          borderColor: "#AAAAAA",
          marginTop: "50px",
          marginBottom: "50px",
        }}
      />
      <div id="contact" ref={contactRef}>
        <Demo />
      </div>
    </div>
  );
}
