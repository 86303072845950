import React from "react";
import "./Working.css";
import WorkingStep01a from "../graphics/images/working-step-01a.png";
import WorkingStep01b from "../graphics/images/working-step-01b.png";
import WorkingStep02 from "../graphics/images/working-step-02.png";
import WorkingStep03 from "../graphics/images/working-step-03.png";
import WorkingStep04 from "../graphics/images/working-step-04.png";
import { Badge } from "@chakra-ui/react";

export default function Working() {
  return (
    <div className="working-container">
      <div className="how-it-works">HOW IT WORKS</div>
      <div className="working-step-row">
        <div className="working-steps">
          <div className="step-label">STEP 1A</div>
          <div className="step-description">
            Engage Employees through Surveys, Polls, and Feedback Channels
          </div>
        </div>
        <div className="working-graphics">
          <img src={WorkingStep01a} alt="Working Step 1" />
        </div>
      </div>
      <div className="working-step-row">
        <div className="working-steps">
          <div className="step-label">STEP 1B</div>
          <div>
            <Badge ml="1" colorScheme="green" style={{ paddingTop: "1px" }}>
              Coming Soon
            </Badge>
          </div>
          <div className="step-description">
            Employees interacts with Conversational AI tool, which effectively
            infers and interprets their input, and analyze it.
          </div>
        </div>
        <div className="working-graphics">
          <img src={WorkingStep01b} alt="Working Step 1" />
        </div>
      </div>
      <div className="working-step-row">
        <div className="working-steps">
          <div className="step-label">STEP 2</div>
          <div className="step-description">
            Access Real-time, In-depth Analysis Reports Based on Employee
            Interactions
          </div>
        </div>
        <div className="working-graphics">
          <img src={WorkingStep02} alt="Working Step 2" />
        </div>
      </div>
      <div className="working-step-row">
        <div className="working-steps">
          <div className="step-label">STEP 3</div>
          <div className="step-description">
            Pinpoint Areas of Improvement and Bridge Organizational Gaps
          </div>
        </div>
        <div className="working-graphics">
          <img src={WorkingStep03} alt="Working Step 3" />
        </div>
      </div>
      <div className="working-step-row">
        <div className="working-steps">
          <div className="step-label">STEP 4</div>
          <div className="step-description">
            Enhance Work Culture by Acting on Employee Responses
          </div>
        </div>
        <div className="working-graphics">
          <img src={WorkingStep04} alt="Working Step 4" />
        </div>
      </div>
    </div>
  );
}
