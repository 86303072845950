import React from "react";
import "./Demo.css";
import { Button } from "@chakra-ui/react";

export default function Demo() {
  return (
    <div className="demo-outer-container">
      <div>
        <div className="demo-description">Get started</div>
        <div
          style={{
            fontSize: "24px",
            color: "#0072BB",
            textAlign: "center",
          }}
        >
          Get in touch with us to know more about CoreCogni
        </div>
        <div className="demo-button-container">
          <a href="https://tally.so/r/m6L7DA" target="_blank" rel="noreferrer">
            <Button className="demo-button" colorScheme="brand" width="200px">
              Book A Demo
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
}
