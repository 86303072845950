import React from "react";
import { ReactComponent as CoreCogni } from "../graphics/CoreCogni.svg";
import "./SummaryLogo.css";
import { CCircle } from "react-bootstrap-icons";

export default function SummaryLogo() {
  return (
    <div className="corecogni-logo-features-container">
      <CoreCogni className="corecogni-logo-features" />
      <div
        style={{
          marginTop: "30px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <CCircle color={"gray"} size={14} />
          </div>
          <div style={{ marginLeft: "5px", color: "gray" }}>
            2024 CoreCogni. All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
}
